<template>
	<section class="packages-section">
		<div class="row header-row">
			<div class="columns column12 align-center">
				<picture>
					<source :srcset="imageWebp" type="image/webp" />
					<source :srcset="image" />
					<img :src="image" :alt="imageAlt" class="icon-image fade-animate fade-up" loading="lazy" />
				</picture>
				<h2>
					{{ header }}
				</h2>
				<div v-parse-links v-html="content" />
			</div>
		</div>
		<div class="row carousel-row">
			<bc-carousel
				ref="carousel"
				:items-to-show="1"
				class="packages-slider"
				:wrap-around="true"
				:breakpoints="breakpoints"
			>
				<template #addons>
					<bc-navigation class="slider-navigation">
						<template #next>
							<div class="slider-button slider-next" />
						</template>
						<template #prev>
							<div class="slider-button slider-prev" />
						</template>
					</bc-navigation>
				</template>
				<bc-slide v-for="item in items" :key="item.ID" class="package-item slider-item">
					<div class="image-holder">
						<nuxt-link :to="localePath(`/${item.filename}`)">
							<div class="image">
								<picture>
									<source :srcset="item.cardImageWebp" type="image/webp" />
									<source :srcset="item.cardImage" />
									<img :src="item.cardImage" :alt="item.cardImageAlt" loading="lazy" />
								</picture>
							</div>
						</nuxt-link>
					</div>
				</bc-slide>
			</bc-carousel>
		</div>
		<div class="row button-row">
			<div class="columns column12 align-center">
				<nuxt-link class="button button-cta" :to="localePath('packages')">
					{{ $t('allPackages') }}
				</nuxt-link>
			</div>
		</div>
	</section>
</template>

<script setup>
defineProps({
	header: { type: String, default: '' },
	content: { type: String, default: '' },
	image: { type: String, default: '' },
	imageWebp: { type: String, default: '' },
	imageAlt: { type: String, default: '' },
	items: { type: Array, default: () => [] },
});
const breakpoints = {
	700: {
		itemsToShow: 3,
	},
	1080: {
		itemsToShow: 5,
	},
};
</script>

<style lang="scss" scoped>
.packages-section {
	background: var(--primary-background-color);

	h2 {
		color: var(--header-color);
	}

	.header-row {
		max-width: 500px;
	}

	a {
		color: var(--body-color);
		text-decoration: none;
		text-transform: uppercase;
	}
}

.carousel {
	width: 100%;
	padding: 10px 0 75px;
}

.carousel-row {
	max-width: 1420px;
	padding: 0 50px;
}

.button-row {
	margin: 25px auto 0;
}

/* stylelint-disable selector-class-pattern */
:deep(.slider-navigation) {
	position: absolute;
	bottom: 0;
	left: calc(50% - 75px);
	top: unset;
	transform: unset;
	width: 67px;
	height: 67px;
	margin: 0;
	padding: 0;

	&.carousel__next {
		left: unset;
		right: calc(50% - 75px);
	}
}

.carousel__slide {
	position: relative;
	z-index: 1;
	transform: scale(0.92);
	transition: margin 0.3s ease-in-out;

	&:hover {
		transform: scale(0.92);
		margin: 0 10px 0 -10px;
	}
}

.carousel__slide--sliding {
	transition: all 0.3s ease-in-out;
}

.carousel__slide--active {
	transform: scale(1.35);
	z-index: 10;

	&:hover {
		transform: scale(1.35);
		margin: 0 20px 0 -20px;
	}
}

.carousel__slide--next,
.carousel__slide--prev {
	transform: scale(1.18);
	z-index: 6;

	&:hover {
		transform: scale(1.18);
		margin: 0 15px 0 -15px;
	}
}

.image-holder {
	position: relative;

	a {
		z-index: 1;
		position: relative;

		&::after {
			display: none;
		}
	}
}

.slider-item {
	display: flex;
	width: 20%;
	margin: 0;
	flex-flow: column wrap;
	position: relative;
	justify-content: flex-start;
	align-items: flex-start;
}

@media (max-width: 1080px) {
	.carousel__slide--active {
		transform: scale(1.15);

		&:hover {
			transform: translate(-18px, 0) scale(1.15);
		}
	}

	.carousel__slide--next,
	.carousel__slide--prev {
		transform: scale(0.9);

		&:hover {
			transform: translate(-12px, 0) scale(0.9);
		}
	}
}

@media (max-width: 700px) {
	.carousel__slide--active {
		transform: scale(0.9);

		&:hover {
			transform: translate(-18px, 0) scale(0.9);
		}
	}
}
/* stylelint-enable selector-class-pattern */
</style>
